const en = {
  "homeInfo" : "Enjoy <strong>deals</strong> on your home internet <strong>monthly</strong> fee and <strong>unlimited data</strong> in your mobile plan or packages. <strong>Combine</strong> Internet & IPTV services with <strong>up to 5</strong> mobile lines in a single group",
  "itemAlbtelecomTitle" : "Up to 50% discount",
  "itemAlbtelecomBody" : "Get a discount on your <strong>fixed internet</strong> fee, <strong>IPTV</strong>, or <strong>HomeNet</strong> for every mobile member who joins the group with up to 5 members.",
  "itemOneTitle": "Unlimited data",
  "itemOneBody": "Surf <strong>unlimited data</strong> with Ultra Unlimited Plans or enjoy 2GB of <strong>free monthly data</strong> with other packages.",
  "itemPrimaryTitle": "Additional benefits",
  "itemPrimaryBody": "Get <strong>Smartphones</strong>, Wi-Fi Extenders, and other accessories on discounts or through monthly <strong>installments</strong>, combined with contract plans.",
  "applyNow": "Apply now!",
  "mobileBannerTitle": "Choose the power to combine",
  "bannerText": "Exclusive benefits when the family is ONE & ALBtelecom",
  "faq": "FAQ",
  "nameLastname": "Name Lastname",
  "city": "City",
  "phoneNumber": "Phone number",
  "other": "Other",
  "albtelectomAndOne": "ALBtelecom and One",
  "iAmCustomerOF": "I am customer of",
  "chooseCompany": "Choose Company",
  "successTitle": "Thank you for your application.",
  "successBody1": "Your request is being reviewed and",
  "successBody2": "you will be contacted by our agents within 48 hours.",
  "formHeader": "Submit your info and enjoy all the benefits by creating the One Super Family group!",
  "newOther": "New / Other",
  "viewTermsAndConditions": "Terms and Conditions",
}
export default en
