const al = {
  "homeInfo" : "Përfito <strong>ulje në faturën mujore</strong> të internetit të shtëpisë, IPTV-së dhe  <strong>internet pa limit</strong> në paketën apo planin tënd në mobile. Bashko <strong>në një grup të vetëm</strong> shërbimin e internetit & IPTV-së me <strong>deri në 5 numra</strong> mobile.",
  "itemAlbtelecomTitle" : "Deri në 50% ulje",
  "itemAlbtelecomBody" : "Përfito ulje në tarifën e <strong>internetit fiks, IPTV</strong> ose <strong>HomeNet</strong> për çdo pjesëtar që i bashkohet grupit",
  "itemOneTitle": "Internet Pa Limit",
  "itemOneBody": "Lundro me <strong>internet pa limit</strong> me planet Ultra Unlimited ose shijo 2 GB internet 4G+ <strong>falas çdo muaj</strong>, në paketat e tjera.",
  "itemPrimaryTitle": "Përfitime shtesë",
  "itemPrimaryBody": "Merr <strong>Smartphone</strong>, pajisje dhe aksesorë me ulje ose <strong>me këste mujore</strong>, kombinuar me planet me kontratë.",
  "applyNow": "Apliko Tani!",
  "mobileBannerTitle": "Zgjidh fuqinë e kombinimit",
  "bannerText": "Përfitime ekskluzive kur familja është ONE & ALBtelecom",
  "faq": "Pyetje të shpeshta",
  "nameLastname": "Emër Mbiemër",
  "city": "Qyteti",
  "phoneNumber": "Kontakt (mobile)",
  "other": "Tjetër",
  "albtelectomAndOne": "ALBtelecom dhe One",
  "iAmCustomerOF": "Jam Klient i",
  "chooseCompany": "Zgjidh Kompaninë",
  "successTitle": "Faleminderit për aplikimin.",
  "successBody1": "Kërkesa juaj po shqyrtohet dhe ju",
  "successBody2": "do të kontaktoheni nga agjentët tanë brenda 48 orësh.",
  "formHeader": "Plotëso të dhënat dhe shijo të gjitha përfitimet duke krijuar grupin One Super Familja!",
  "newOther": "I Ri / Tjetër",
  "viewTermsAndConditions": "Kushtet dhe Afatet",
}
export default al
