import React from 'react';
import {Switch, BrowserRouter as Router, Redirect, Route} from 'react-router-dom';
import ScrollToTop from '@router/ScrollToTop';
import HomePage from '@pages/HomePage';
import AboutUsPage from "@pages/AboutUsPage";
import ServicesPage from "@pages/ServicesPage";
import ProjectsPage from "@pages/ProjectsPage";
import ContactPage from "@pages/ContactPage";
import CareersPage from "@pages/CareersPage";
import PokPage from "@pages/Projects/PokPage";
import SmipPage from "@pages/Projects/SmipPage";
import OnePage from "@pages/Projects/OnePage";
import KeshPage from "@pages/Projects/KeshPage";
import FfmsPage from "@pages/Projects/FfmsPage";

const Routes = () => {
  return (
    <Router>
      <ScrollToTop/>
      <Switch>
        <Route exact path="/" component={HomePage}/>
        <Route exact path='/about-us' component={AboutUsPage}/>
        <Route exact path="/projects" component={ProjectsPage}/>
        <Route exact path="/projects/pok" component={PokPage}/>
        <Route exact path="/projects/smip" component={SmipPage}/>
        <Route exact path="/projects/one" component={OnePage}/>
        <Route exact path="/projects/kesh" component={KeshPage}/>
        <Route exact path="/projects/ffms" component={FfmsPage}/>
        <Route exact path="/services" component={ServicesPage}/>
        <Route exact path="/careers" component={CareersPage}/>
        <Route exact path="/contact" component={ContactPage}/>
        <Redirect from="*" to="/"/>
      </Switch>
    </Router>
  );
};

export default Routes;
