import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '@assets/svg/whiteLogo.svg'
import AOS from "aos";
import 'aos/dist/aos.css'

const Footer = () => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  return (
    <div
      className="w-full bottom-0 bg-gradient-to-br to-[#1E1E1E] from-[#000002] border-t-1 border-white border-opacity-40 font-inter">
      <div className="3xl:max-w-7xl 3xl:mx-auto 3xl:px-0 py-8 xl:py-16 mx-5 md:mx-10 xl:mx-32">
        <div className="flex flex-col md:flex-row mt-10">
          <div
            className='flex justify-between md:border-r-1 border-b-1 border-opacity-40 md:border-b-0 border-white pb-5 md:pr-5 lg:pr-10 xl:pr-40'
            data-aos="fade-right" data-aos-offset="50"
            data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true">
            <ul className="pt-4 mx-4 flex flex-row">
              <img src={logo} className='h-8 xl:h-14' alt='Gogėl'/>
              <div
                className='font-sans text-white tracking-widest leading-4 ml-3 lg:ml-5 font-semibold text-base md:text-lg xl:text-2xl mt-3 xl:mt-[14px]'>NEBULA
              </div>
            </ul>
            <ul className="mx-2 md:mx-5 xl:mx-16">
              <li className='mb-2'>
                <Link to="/"
                      className="font-light mb-5 text-xs md:text-sm lg:text-base text-white hover:text-yellow-300 hover:duration-700 hover:opacity-80">Home</Link>
              </li>
              <li className='mb-2'>
                <Link to="/about-us"
                      className="font-light mb-5 text-white text-xs md:text-sm lg:text-base hover:text-yellow-300 hover:duration-700 hover:opacity-80">About
                  Us</Link>
              </li>
              <li className='mb-2'>
                <Link to="/projects"
                      className="font-light mb-5 text-white text-xs md:text-sm lg:text-base hover:text-yellow-300 hover:duration-700 hover:opacity-80">Projects</Link>
              </li>
            </ul>
            <ul className="md:pr-0">
              <li className='mb-2'>
                <Link to="/services"
                      className="font-light mb-5 text-white text-xs md:text-sm lg:text-base hover:text-yellow-300 hover:duration-700 hover:opacity-80">Services</Link>
              </li>
              <li className='mb-2'>
                <Link to="/careers"
                      className="font-light mb-5 text-white text-xs md:text-sm lg:text-base hover:text-yellow-300 hover:duration-700 hover:opacity-80">Careers</Link>
              </li>
              <li className='mb-2'>
                <Link to="/contact"
                      className="font-light mb-5 text-white text-xs md:text-sm lg:text-base hover:text-yellow-300 hover:duration-700 hover:opacity-80">Contact</Link>
              </li>
            </ul>
          </div>
          <div className='flex flex-col lg:flex-row mt-6 md:mt-0 md:ml-20 xl:ml-0 mx-auto justify-center' data-aos="fade-left"
               data-aos-offset="50"
               data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true">
            <div className='flex-col gap-x-24 text-white lg:ml-10 xl:ml-32 lg:self-center'>
              <div className="text-white tracking-widest leading-4 font-semibold text-2xl mt-3">Newsletter</div>
              <div className='mt-3 tracking-tight font-light text-sm'>Be part of our newsletter
              </div>
            </div>
            <button className='lg:ml-12 mt-5 lg:mt-0 lg:self-center px-18 py-2.5 email-button' onClick={() => {
              window.open("mailto:" + 'info@nebula.ai')
            }}>
              <span className='hover:animate-bounce font-sans'>{`{ Email }`}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;
